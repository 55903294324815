import React, { useState } from 'react';
import Cookies from 'js-cookie';
import Header from './Header';
import HeaderConnected from './HeaderConnected';
import Footer from './Footer';
import { useTheme } from '../contexts/ThemeContext';
import { useTranslation } from 'react-i18next';
import '../stylesheets/detection.css';
import { Select, Option, ButtonGroup, Button, Typography } from "@material-tailwind/react";
import { Slider } from "@mui/material";

function Webcam() {
    // State variables for selectedModel and value
    const [selectedModel, setSelectedModel] = useState(null);
    const [value, setValue] = useState(0.5);

    // Check if a user is logged in
    const loggedUser = Cookies.get('loggedUser');
    const isLoggedIn = loggedUser !== undefined;

    const { darkMode, toggleDarkMode } = useTheme();
    const { t } = useTranslation();

    // Handle click event for opening a link
    const handleClick = () => {
        // Construct the link for webcam detection
        const link = `${selectedModel}?confiance=${value}`;
        // Open the link in a new tab
        window.open(link, '_blank');
    };

    // Handle change event for selecting a model
    const handleModelChange = (e) => {
        setSelectedModel(e);
    };

    // Handle change event for adjusting the confidence value
    const handleChange = (e) => {
        setValue(parseFloat(e.target.value));
    };

    return (
        <div className={`App ${darkMode ? 'dark' : 'light'}`}>
            {isLoggedIn ? <HeaderConnected /> : <Header />}
            <div className="content-detection">
                <h1 className="block text-4xl flex justify-center font-semibold leading-tight tracking-normal text-transparent bg-blue-500 bg-clip-text">{t('detec_webcam')}</h1>
                <div className="flex flex-col items-center justify-center">
                    <div className="model-selection">
                        {/* Dropdown for selecting the detection model */}
                        <Select
                            className="model"
                            id="model-select"
                            value={selectedModel}
                            onChange={handleModelChange}
                            color="#060314"
                            label={t('select_ia')}
                        >
                            <Option value="https://pythonserver.raccoonvision.com/yoloWebcam">YoloV8</Option>
                            <Option value="https://pythonserver.raccoonvision.com/ssdWebcam" disabled={!isLoggedIn}>Ssd Mobilenet</Option>
                        </Select>
                        {!isLoggedIn && (
                            <p className="text-sm text-gray-500 mt-1">{t('phrase_login_access')}</p>
                        )}  
                    </div>
                    <div className="open-link-webcam-button flex flex-col items-center justify-center ">
                        {/* Button for opening the webcam detection link */}
                        <Button  onClick={handleClick}>{t('lien')}</Button>
                        {/* Warning message if no model is selected */}
                        {selectedModel === null && <p className="warning-message" style={{ color: 'red' }}>{t('phrase_select_ia')}</p>}
                    </div>
                </div>
                <div className="flex animate-pulse flex-wrap w-full items-center gap-8 margin-top-30px">
                    <div className="container-size">
                        <div className="aspect-w-16 aspect-h-9 w-full h-full">
                            <div class="grid h-full place-items-center rounded-lg bg-gray-300">
                                {/* Placeholder for webcam preview */}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="2"
                                    stroke="currentColor"
                                    className="w-20 h-20 text-gray-500"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z">
                                    </path>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="after-detection-content">
                    <div className='detection-factors'>
                        {/* Slider for adjusting the confidence value */}
                        <Typography id="non-linear-slider" gutterBottom>
                            {t('fiabilite')} : {value}
                        </Typography>
                        <Slider
                            value={value}
                            min={0}
                            step={0.01}
                            max={1}
                            aria-label="Small"
                            onChange={handleChange}
                            valueLabelDisplay="auto"
                            color="primary"
                            aria-labelledby="non-linear-slider"
                        />
                    </div>
                    {/*<div className="save-buttons">
                        Button group for save and download options
                        <ButtonGroup ripple={true} color="blue">
                            <Button>{t('save')}</Button>
                            <Button>{t('download')}</Button>
                        </ButtonGroup>
                    </div>*/}
                </div>
            </div>
            {/* Footer component */}
            <Footer />
        </div>
    );
}

export default Webcam;
