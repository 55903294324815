// Import necessary modules and components from external files and libraries
import React from 'react';
import Header from './Header';
import HeaderConnected from './HeaderConnected';
import Footer from './Footer';
import Cookies from 'js-cookie';
import { useTheme } from '../contexts/ThemeContext';
import '../stylesheets/homepage.css'; // Import styles for the homepage
import { useTranslation } from 'react-i18next';
import imgImage from "../images/image-detection-card.jpg";
import imgVideo from "../images/video-detection-card.jpg";
import imgWebcam from "../images/webcam-detection-card.jpg";
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Button,
} from "@material-tailwind/react"; // Import Material Tailwind components for styling

// Component to render a list of detection cards
function CardList() { 

    const { t } = useTranslation();

    const cardsItems = [
        {
            imgLink: imgImage,
            label: t('detec_image'),
            description: t('test_detection_image'),
            models: "Detectron 2 - TorchVision - YoloV8 - SSD Mobilenet",
            link: "/image",
        },
        {
            imgLink: imgVideo,
            label: t('detec_video'),
            description: t('test_detection_video'),
            models: "YoloV8 - SSD Mobilenet",
            link: "/video",
        },
        {
            imgLink: imgWebcam,
            label: t('detec_webcam'),
            description: t('test_detection_webcam'),
            models: "YoloV8 - SSD Mobilenet",
            link: "/webcam",
        },
    ];

    return (
        <React.Fragment>
            {cardsItems.map(({ imgLink, label, description, models, link }, key) => (
                // Render each card with specific details
                <div key={key}>
                    <Card className="mt-6 w-96">
                        <a href={link}>
                            <CardHeader
                                color="blue-gray"
                                className="relative h-56 transition-transform transform hover:translate-y-[-10px]"
                                style={{
                                    backgroundImage: `url(${imgLink})`,
                                    backgroundPosition: "center center",
                                    backgroundSize: getBackgroundSize(key),
                                }}
                            />
                        </a>
                        <CardBody>
                            <Typography variant="h3" color="blue-gray" className="mb-4">
                                {label}
                            </Typography>
                            <Typography className="text-gray-700">
                                {description}
                            </Typography>
                            <Typography variant="h5" color="blue-gray" className="mb-2 mt-5">
                                {t('modeles_dispos')}
                            </Typography>
                            <Typography className="text-gray-700">
                                {models}
                            </Typography>
                        </CardBody>
                        <CardFooter className="pt-0">
                            <div className="flex items-center justify-center">
                                <a href={link}>
                                    <Button color="blue">{t('try_it')}</Button>
                                </a>
                            </div>
                        </CardFooter>
                    </Card>
                </div>
            ))}
        </React.Fragment>
    );
}

// Function to determine background size based on the card index
function getBackgroundSize(index) {
    switch (index) {
        case 0:
            return "90%";
        case 1:
            return "69%"; 
        case 2:
            return "88%"; 
        default:
            return "cover";
    }
}

// Main component for the homepage
function HomePage() {
    // Check if the user is logged in
    const loggedUser = Cookies.get('loggedUser');
    const isLoggedIn = loggedUser !== undefined;

    const { darkMode, toggleDarkMode } = useTheme();
    const { t } = useTranslation();

    return (
        <div className={`App ${darkMode ? 'dark' : 'light'}`}>
            {/* Display HeaderConnected if the user is logged in, otherwise display Header */}
            {isLoggedIn ? <HeaderConnected /> : <Header />}
            <div className="content-homepage">
                {/* Title and introduction */}
                <h1 className="title-homepage block text-4xl flex justify-center font-semibold leading-tight tracking-normal text-transparent mt-20 bg-blue-500 bg-clip-text">{t('titre_accueil')}</h1>
                <Typography variant="h5" color="blue-gray" id="introduction_homepage" className="font-medium text-justify ml-0 mr-40 pl-0 pr-0 sm:(ml-60 mr-0 pl-0 pr-10) mb-16">
                    {t('description_site')}
                    <br />
                    {t('description_site2')}
                </Typography>

                {/* Render the list of cards */}
                <div className="content-cards-homepage flex gap-5 flex-col items-center justify-center sm:flex-row">
                    <CardList />
                </div>
            </div>

            {/* Display Footer */}
            <Footer />
        </div>
    );
}

// Export the component as the default export
export default HomePage;
