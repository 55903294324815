import React from 'react';
import Cookies from 'js-cookie';
import Header from './Header';
import HeaderConnected from './HeaderConnected';
import Footer from './Footer';
import '../stylesheets/legal-docs.css';
import { useTheme } from '../contexts/ThemeContext';
import { useTranslation } from 'react-i18next';

// Functional component for Legal Notice
export const LegalNotice = () => {
    // Check if the user is logged in
    const loggedUser = Cookies.get('loggedUser');
    const isLoggedIn = loggedUser !== undefined;

    const { darkMode } = useTheme();
    const { t } = useTranslation();

    return (
        <div className={`App ${darkMode ? 'dark' : ''}`}>
            {/* Render the appropriate header based on user login status */}
            {isLoggedIn ? <HeaderConnected /> : <Header />}
            <div className="content-docs">
                {/* Legal Notice Section */}
                <h1 className="title-homepage block text-3xl flex justify-center font-semibold leading-tight tracking-normal text-transparent bg-blue-500 bg-clip-text">{t('legal_notice')}</h1>
                {/* Section 1: Publisher of the application */}
                <h2>1. {t('editeur')}</h2>
                <div class="border border-2 rounded p-4 pr-10 pl-10 ml-40 mr-40 shadow-md">
                    <p>RECCTech</p>
                    <p>8 Rue Michel de Montaigne, 56000 Vannes</p>
                    <p>desse.e2204127@etud.univ-ubs.fr</p>
                </div>
                {/* Section 2: Director of publication */}
                <h2>2. {t('directeur')}</h2>
                <div class="border border-2 rounded p-4 pr-10 pl-10 ml-40 mr-40 shadow-md">
                    <p>Raphaël DESSE</p>
                    <p>desse.e2204127@etud.univ-ubs.fr</p>
                </div>
                {/* Section 3: Intellectual Property */}
                <h2>3. {t('propriete_intellectuelle')}</h2>
                <div class="border border-2 rounded p-4 pr-10 pl-10 ml-40 mr-40 shadow-md">
                    <p>{t('phrase2_propriete_intellectuelle')}</p>
                </div>
                {/* Section 4: Collection of Personal Data */}
                <h2>4. {t('personal_data_collection')}</h2>
                <div class="border border-2 rounded p-4 pr-10 pl-10 ml-40 mr-40 shadow-md">
                    <p>{t('phrase_personal_data_collection')}</p>
                </div>
                {/* Section 5: Use of Artificial Intelligence */}
                <h2>5. {t('use_ai')}</h2>
                <div class="border border-2 rounded p-4 pr-10 pl-10 ml-40 mr-40 shadow-md">
                    <p>{t('phrase_use_ai')}</p>
                </div>
                {/* Section 6: Liability */}
                <h2>6. {t('liability')}</h2>
                <div class="border border-2 rounded p-4 pr-10 pl-10 ml-40 mr-40 shadow-md">
                    <p>{t('phrase_liability')}</p>
                </div>
                {/* Section 7: Contact */}
                <h2>7. {t('contact')}</h2>
                <div class="border border-2 rounded p-4 pr-10 pl-10 ml-40 mr-40 shadow-md">
                    <p>F{t('phrase3_contact')}</p>
                    <div className="flex justify-center">
                        <p><a href="mailto:desse.e2204127@etud.univ-ubs.fr" className="text-blue-500 hover:text-inherit">desse.e2204127@etud.univ-ubs.fr</a>.</p>
                    </div>
                </div>

                {/* Image Credits Section */}
                <h1 className="title-homepage block text-3xl flex justify-center font-semibold leading-tight tracking-normal text-transparent bg-blue-500 bg-clip-text">{t('credit_image')}</h1>
                <h2>{t('credit_image_home')}</h2>
                <div class="border border-2 rounded p-4 pr-10 pl-10 ml-40 mr-40 shadow-md">
                    <p>{t('card_image')}&nbsp;<a className="text-blue-500 hover:text-inherit" href="https://fr.freepik.com/psd-gratuit/rendu-3d-icone-interface-utilisateur_20546699.htm#query=image%203d&position=0&from_view=search&track=ais&uuid=3e8a8bae-bbec-48a7-b3a4-11f3e8fba538">Freepik</a></p>
                    <p>{t('card_video')}&nbsp;<a className="text-blue-500 hover:text-inherit" href="https://fr.freepik.com/psd-gratuit/illustration-icone-cinema-3d-projecteur_54170327.htm#query=video%203d&position=4&from_view=search&track=ais&uuid=40ef4582-b2ae-4cea-a5ec-790be1af9ad9">Freepik</a></p>
                    <p>{t('card_webcam')}&nbsp;<a className="text-blue-500 hover:text-inherit" href="https://www.freepik.com/free-psd/historical-museum-camera-icon_39427027.htm#query=webcam%203d&position=0&from_view=author&uuid=ac3a2f46-e513-4a8b-8560-0e47393ab160">Freepik</a></p>
                </div>
            </div>
            <Footer />
        </div>
    );
}

// Component for Terms of Use
export const TermsOfUse = () => {
    // Check if the user is logged in
    const loggedUser = Cookies.get('loggedUser');
    const isLoggedIn = loggedUser !== undefined;

    const { darkMode } = useTheme();
    const { t } = useTranslation();

    return (
        <div className={`App ${darkMode ? 'dark' : ''}`}>
            {/* Render the appropriate header based on user login status */}
            {isLoggedIn ? <HeaderConnected /> : <Header />}
            <div className="content-docs">
                {/* Terms of Use Section */}
                <h1 className="title-homepage block text-3xl flex justify-center font-semibold leading-tight tracking-normal text-transparent bg-blue-500 bg-clip-text">{t('cond_util')} - RaccoonVision</h1>
                {/* Section 1: Acceptance of the Terms of Use */}
                <h2>1. {t('accept_terms')}</h2>
                <div class="border border-2 rounded p-4 pr-10 pl-10 ml-40 mr-40 shadow-md">
                    <p>{t('phrase_accept_terms')}</p>
                </div>
                {/* Section 2: Use of the Application */}
                <h2>2. {t('use_app')}</h2>
                <div class="border border-2 rounded p-4 pr-10 pl-10 ml-40 mr-40 shadow-md">
                    <p>{t('phrase_use_app')}</p>
                </div>
                {/* Section 3: User Accounts */}
                <h2>3. {t('user_account')}</h2>
                <div class="border border-2 rounded p-4 pr-10 pl-10 ml-40 mr-40 shadow-md">
                    <p>{t('phrase_user_account')}</p>
                </div>
                {/* Section 4: Data Collection */}
                <h2>4. {t('data_collection')}</h2>
                <div class="border border-2 rounded p-4 pr-10 pl-10 ml-40 mr-40 shadow-md">
                    <p>{t('phrase_data_collection')}</p>
                </div>
                {/* Section 5: Intellectual Property */}
                <h2>5. {t('propriete_intellectuelle')}</h2>
                <div class="border border-2 rounded p-4 pr-10 pl-10 ml-40 mr-40 shadow-md">
                    <p>{t('phrase_propriete_intellectuelle')}</p>
                </div>
                {/* Section 6: Responsibilities */}
                <h2>6. {t('responsabilites')}</h2>
                <div class="border border-2 rounded p-4 pr-10 pl-10 ml-40 mr-40 shadow-md">
                    <p>{t('phrase_responsabilites')}</p>
                </div>
                {/* Section 7: Changes to the Terms of Use */}
                <h2>7. {t('change_terms')}</h2>
                <div class="border border-2 rounded p-4 pr-10 pl-10 ml-40 mr-40 shadow-md">
                    <p>{t('phrase_change_terms')}</p>
                </div>
                {/* Section 8: Contact */}
                <h2>8. {t('contact')}</h2>
                <div class="border border-2 rounded p-4 pr-10 pl-10 ml-40 mr-40 shadow-md">
                    <p>{t('phrase2_contact')}</p>
                    <div className="flex justify-center">
                        <p><a href="mailto:desse.e2204127@etud.univ-ubs.fr" className="text-blue-500 hover:text-inherit">desse.e2204127@etud.univ-ubs.fr</a>.</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

// Component for Privacy Policy
export const PrivacyPolicy = () => {
    // Check if the user is logged in
    const loggedUser = Cookies.get('loggedUser');
    const isLoggedIn = loggedUser !== undefined;

    const { darkMode } = useTheme();
    const { t } = useTranslation();

    return (
        <div className={`App ${darkMode ? 'dark' : ''}`}>
            {/* Render the appropriate header based on user login status */}
            {isLoggedIn ? <HeaderConnected /> : <Header />}
            <div className="content-docs">
                {/* Privacy Policy Section */}
                <h1 className="title-homepage block text-3xl flex justify-center font-semibold leading-tight tracking-normal text-transparent bg-blue-500 bg-clip-text">{t('politique_conf')} - RaccoonVision</h1>
                {/* Section 1: Data Collection */}
                <h2>1. {t('data_collection')}</h2>
                <div class="border border-2 rounded p-4 pr-10 pl-10 ml-40 mr-40 shadow-md">
                    <p>{t('phrase_collecte')}</p>
                </div>
                {/* Section 2: Use of Data */}
                <h2>2. {t('use')}</h2>
                <div class="border border-2 rounded p-4 pr-10 pl-10 ml-40 mr-40 shadow-md">
                    <p>{t('phrase_use1')}</p>
                    <ul className='ml-10 list-disc'>
                        <li>{t('phrase_use2')}</li>
                        <li>{t('phrase_use3')}</li>
                        <li>{t('phrase_use4')}</li>
                        <li>{t('phrase_use5')}</li>
                    </ul>
                </div>
                {/* Section 3: Data Retention */}
                <h2>3. {t('conservation')}</h2>
                <div class="border border-2 rounded p-4 pr-10 pl-10 ml-40 mr-40 shadow-md">
                    <p>{t('phrase_conservation')}</p>
                </div>    
                {/* Section 4: Data Security */}
                <h2>4. {t('securite')}</h2>
                <div class="border border-2 rounded p-4 pr-10 pl-10 ml-40 mr-40 shadow-md">
                    <p>{t('phrase_securite')}</p>
                </div>
                {/* Section 5: Access and Control of Data */}
                <h2>5. {t('controle')}</h2>
                <div class="border border-2 rounded p-4 pr-10 pl-10 ml-40 mr-40 shadow-md">
                    <p>{t('phrase_controle')}</p>
                    <div className="flex justify-center">
                        <p><a href="mailto:desse.e2204127@etud.univ-ubs.fr" className="text-blue-500 hover:text-inherit">desse.e2204127@etud.univ-ubs.fr</a>.</p>
                    </div>
                </div>
                {/* Section 6: Cookies */}
                <h2>6. {t('cookies')}</h2>
                <div class="border border-2 rounded p-4 pr-10 pl-10 ml-40 mr-40 shadow-md">
                    <p>{t('phrase_cookies')}</p>
                </div>
                {/* Section 7: Changes to the Privacy Policy */}
                <h2>7. {t('modif_pol_conf')}</h2>
                <div class="border border-2 rounded p-4 pr-10 pl-10 ml-40 mr-40 shadow-md">
                    <p>{t('phrase_modif_pol_conf')}</p>
                </div>
                {/* Section 8: Contact */}
                <h2>8. {t('contact')}</h2>
                <div className={`border border-2 rounded p-4 pr-10 pl-10 ml-40 mr-40 shadow-md`}>
                    <p>{t('phrase_contact')}</p>
                    <div className="flex justify-center">
                        <p><a href="mailto:desse.e2204127@etud.univ-ubs.fr" className="text-blue-500 hover:text-inherit">desse.e2204127@etud.univ-ubs.fr</a>.</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

