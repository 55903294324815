// Import necessary modules and components from external files and libraries
import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import Header from './Header';
import HeaderConnected from './HeaderConnected';
import Footer from './Footer';
import { useTheme } from '../contexts/ThemeContext';
import { useTranslation } from 'react-i18next';
import '../stylesheets/detection.css';
import { FileInput, Label } from 'flowbite-react'; // Importing components for file input
import { Select, Option, Spinner, ButtonGroup, Button, Typography } from "@material-tailwind/react"; // Import Material Tailwind components
import { Slider } from "@mui/material"; // Importing slider component

// Component for image detection
function Image() {
    // State variables for file, image blob, loading indicator, selected model, and slider value
    const [file, setFile] = useState(null);
    const [imageBlob, setImageBlob] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedModel, setSelectedModel] = useState(null);
    const [value, setValue] = useState(0.5);

    // Check if the user is logged in
    const loggedUser = Cookies.get('loggedUser');
    const isLoggedIn = loggedUser !== undefined;

    const { darkMode, toggleDarkMode } = useTheme();
    const { t } = useTranslation();

    // Event handler for model selection change
    const handleModelChange = (e) => {
        setSelectedModel(e);
    };

    // Event handler forimage saving
    const handleSave = async () => {
        if (!imageBlob) {
            alert('No image to save.');
            return;
        }
    
        try {
            // Fetch the blob from the imageBlob URL
            const blobResponse = await fetch(imageBlob);
            const blob = await blobResponse.blob();
    
            const formData = new FormData();
            formData.append('image', blob, 'result.jpg');
            formData.append('loggedUser', Cookies.get('loggedUser'));
    
            const response = await axios.post('https://server.raccoonvision.com/api/saveImage', formData);
    
            if (response.data.success) {
                alert(t('success'));
            } else {
                alert('Failed to save image.');
            }
        } catch (error) {
            console.error('Error saving image:', error);
            alert('An error occurred while saving the image.');
        }
    };

    const handleDownload = () => {
        const downloadLink = document.createElement('a');
        downloadLink.href = imageBlob;
        downloadLink.download = 'result.jpg';
        downloadLink.click();
    };

    // Event handler for form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if a model is selected before proceeding
        if (selectedModel === null) {
            console.warn('Please select a mode of detection.');
            return;
        }

        setLoading(true); // Set loading indicator to true

        // Create a FormData object and append necessary data
        const formData = new FormData();
        formData.append('fichier', file);
        formData.append('loggedUser', sessionStorage.getItem('loggedUser'));
        formData.append('selectedModel', selectedModel);
        formData.append('confiance', value);

        try {
            // Make a POST request to the server with the form data
            const response = await axios.post('https://server.raccoonvision.com/api/upload', formData, {
                responseType: 'blob', // Request the server to return data as Blob object
            });

            // Create a Blob object with the data returned by the server
            const blob = new Blob([response.data], { type: 'image/jpeg' });
            const blobUrl = URL.createObjectURL(blob);

            // Update state with the Blob object
            setImageBlob(blobUrl);
        } catch (error) {
            console.error('Error during detection: ', error);
        } finally {
            setLoading(false); // Set loading indicator back to false
        }
    };

    // Event handler for slider value change
    const handleChange = (e) => {
        setValue(parseFloat(e.target.value));
    };

    return (
        <div className={`App ${darkMode ? 'dark' : 'light'}`}>
            {/* Display HeaderConnected if the user is logged in, otherwise display Header */}
            {isLoggedIn ? <HeaderConnected /> : <Header />}
            <div className="content-detection">
                {/* Title for the image detection page */}
                <h1 className="block text-4xl flex justify-center font-semibold leading-tight tracking-normal text-transparent bg-blue-500 bg-clip-text">{t('detec_image')}</h1>

                {/* Container for model selection and file upload */}
                <div className="flex flex-col items-center justify-center">
                    <div className="model-selection">
                        {/* Dropdown for selecting AI model */}
                        <Select
                            className="model"
                            id="model-select"
                            value={selectedModel}
                            onChange={handleModelChange}
                            label={t('select_ia')}
                        >
                            <Option value="yolo">YoloV8</Option>
                            <Option value="detectron" disabled={!isLoggedIn}>Detectron 2</Option>
                            <Option value="torchvision" disabled={!isLoggedIn}>TorchVision</Option>
                            <Option value="ssd" disabled={!isLoggedIn}>Ssd Mobilenet</Option>
                        </Select>
                        {!isLoggedIn && (
                            <p className="text-sm text-gray-500 mt-1">{t('phrase_login_access')}</p>
                        )}
                    </div>

                    {/* File upload section */}
                    <div id="fileUpload" className="flex flex-col items-center justify-center max-w-md">
                        <div className="mb-2 block">
                            <Label htmlFor="fichier" className="text-2xl" value={t('upload')} />
                        </div>
                        <div className="mb-2 block">
                            {/* File input component */}
                            <FileInput id="fichier" helperText={t('phrase_upload')} onChange={(e) => setFile(e.target.files[0])} accept=".jpg, .jpeg" />
                        </div>
                        <div className="start-detection-button flex flex-col items-center justify-center mb-2 block text-white">
                            {/* Button to start detection */}
                            <Button type="submit" disabled={loading} onClick={handleSubmit}>{t('start_detec')}</Button>
                            {selectedModel === null && <p className="warning-message" style={{ color: 'red' }}>{t('phrase_select_ia')}</p>}
                        </div>
                    </div>
                </div>

                {/* Loading indicator and image result */}
                {loading && 
                <div className="flex justify-center pb-3">
                    <Spinner color="#060314" />
                </div>
                }
                {imageBlob ? 
                    <div className="container-size-detection">
                        <div className="flex justify-center items-center">
                            {imageBlob && <img src={imageBlob} className="aspect-w-16 aspect-h-9" alt="Object Recognition Result" />}
                        </div>
                    </div>               
                : (
                    <div className="flex animate-pulse flex-wrap w-full items-center gap-8">
                        <div className="container-size">
                            <div className="aspect-w-16 aspect-h-9 w-full h-full">                              
                                <div className="grid h-full place-items-center rounded-lg bg-gray-300">
                                    {/* Placeholder for image preview */}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={2}
                                        stroke="currentColor"
                                        className="h-20 w-20 text-gray-500"
                                    >
                                        <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                                        />
                                    </svg>
                                </div>                                
                            </div>
                        </div>
                    </div>
                )}

                {/* Section after detection for adjusting factors and saving options */}
                <div className="after-detection-content">
                    <div className='detection-factors'>
                        {/* Display slider for adjusting reliability coefficient */}
                        <Typography id="non-linear-slider" gutterBottom>
                            {t('fiabilite')} : {value}
                        </Typography>
                        <Slider
                            value={value}
                            min={0}
                            step={0.01}
                            max={1}
                            aria-label="Small"
                            onChange={handleChange}
                            valueLabelDisplay="auto"
                            color="primary"
                            aria-labelledby="non-linear-slider"
                        />
                    </div>

                    {/* Save buttons for the detection result */}
                    <div className="save-buttons">
                        <ButtonGroup ripple={true} color="blue">
                            {isLoggedIn && (
                                <Button onClick={handleSave}>{t('save')}</Button>
                            )}
                            <Button onClick={handleDownload} style={{ border: darkMode ? "1px solid #fde047" : "none", borderColor: darkMode ? "#fde047" : "initial" }}>{t('download')}</Button>
                        </ButtonGroup>
                    </div>
                </div>
            </div>
            {/* Display Footer */}
            <Footer />
        </div>
    );
}

// Export the component as the default export
export default Image;
