import React from 'react';
import { IconButton } from "@material-tailwind/react"; // Import des composants nécessaires
import { useTheme } from '../contexts/ThemeContext'; // Import du hook useTheme pour utiliser le mode sombre
import { SunIcon, MoonIcon } from '@heroicons/react/24/outline'; // Import des icônes du soleil et de la lune depuis Heroicons

// Définition du composant ToggleDarkMode
function ToggleDarkMode() {
  const { darkMode, toggleDarkMode } = useTheme(); // Récupération de l'état et de la fonction pour activer/désactiver le mode sombre

  return (
    <IconButton
      variant="text"
      color="blue-gray"
      className="lg:ml-2" // Ajout de la classe pour l'espacement
      onClick={toggleDarkMode} // Appel de la fonction pour activer/désactiver le mode sombre lors du clic
    >
      {darkMode ? (
        <SunIcon className="h-6 w-6" strokeWidth={2} /> // Affichage de l'icône du soleil si le mode sombre est activé
      ) : (
        <MoonIcon className="h-6 w-6" strokeWidth={2} /> // Affichage de l'icône de la lune si le mode sombre est désactivé
      )}
    </IconButton>
  );
}

export default ToggleDarkMode;