import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import Header from './Header';
import HeaderConnected from './HeaderConnected';
import Footer from './Footer';
import { useTheme } from '../contexts/ThemeContext';
import { useTranslation } from 'react-i18next';
import '../stylesheets/detection.css';
import { FileInput, Label } from 'flowbite-react';
import { Select, Option, ButtonGroup, Button, Typography } from "@material-tailwind/react";
import { Slider } from "@mui/material";

function Video() {
  // State to track the selected video file
  const [file, setFile] = useState(null);

  // State to track the selected detection model
  const [selectedModel, setSelectedModel] = useState(null);

  // State to track the confidence value
  const [value, setValue] = useState(0.5);

  // Check if the user is logged in to display the correct Header/navbar
  const loggedUser = Cookies.get('loggedUser');
  const isLoggedIn = loggedUser !== undefined;

  const { darkMode, toggleDarkMode } = useTheme();
  const { t } = useTranslation();


  // Handle model selection change
  const handleModelChange = (e) => {
    setSelectedModel(e);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Verify that selectedModel is defined before proceeding
    if (selectedModel === null) {
      console.warn('Please select a mode of detection.');
      return;
    }

    // Create FormData object to send file and other data
    const formData = new FormData();
    formData.append('fichier', file);
    formData.append('selectedModel', selectedModel);
    formData.append('confiance', value);

    try {
      // Send a POST request to the server for video detection
      const response = await axios.post('https://server.raccoonvision.com/api/uploadVideo', formData);
      const fileName = response.data;

      // Construct the link for the detected video
      const link = `${selectedModel}?confiance=${value}&path=${fileName}`;
        
      // Open the detected video in a new tab
      window.open(link, '_blank');
    } catch (error) {
      console.error('Error during detection: ', error);
    }
  };

  // Handle confidence value change
  const handleChange = (e) => {
    setValue(parseFloat(e.target.value));
  };

  return (
    <div className={`App ${darkMode ? 'dark' : 'light'}`}>
        {isLoggedIn ? <HeaderConnected /> : <Header />}
        <div className="content-detection">
            <h1 className="block text-4xl flex justify-center font-semibold leading-tight tracking-normal text-transparent bg-blue-500 bg-clip-text">{t('detec_video')}</h1>
            <div className="flex flex-col items-center justify-center">
                <div className="model-selection">
                    {/* Dropdown for selecting the detection model */}
                    <Select
                        className="model"
                        id="model-select"
                        value={selectedModel}
                        onChange={handleModelChange}
                        color="#060314"
                        label={t('select_ia')}
                    >
                        <Option value="https://pythonserver.raccoonvision.com/yoloVideo">YoloV8</Option>
                        <Option value="https://pythonserver.raccoonvision.com/ssdVideo" disabled={!isLoggedIn}>Ssd Mobilenet</Option>
                    </Select>
                    {!isLoggedIn && (
                        <p className="text-sm text-gray-500 mt-1">{t('phrase_login_access')}</p>
                    )}
                </div>
                <div id="fileUpload" className="flex flex-col items-center justify-center max-w-md">
                    <div className="mb-2 block">
                        {/* Label for the file input */}
                        <Label htmlFor="fichier" className="text-2xl" value={t('upload')} />
                    </div>
                    <div className="mb-2 block">
                        {/* File input for uploading a video */}
                        <FileInput id="fichier" helperText={t('upload_video')} onChange={(e) => setFile(e.target.files[0])} accept=".mp4" />
                    </div>
                    <div className="start-detection-button flex flex-col items-center justify-center mb-2 block text-white">
                        {/* Button for starting the detection */}
                        <Button type="submit" enctype="multipart/form-data" onClick={handleSubmit}>{t('lien')}</Button>
                        {selectedModel === null && <p className="warning-message" style={{ color: 'red' }}>{t('phrase_select_ia')}</p>}
                    </div>
                </div>
            </div>
            <div className="flex animate-pulse flex-wrap w-full items-center gap-8">
                <div className="container-size">
                    <div className="aspect-w-16 aspect-h-9 w-full h-full">
                        <div class="grid h-full place-items-center rounded-lg bg-gray-300">
                            {/* Placeholder for video preview */}
                            <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                fill="none" 
                                viewBox="0 -13 414 542" 
                                stroke-width="25" 
                                stroke="currentColor"
                                className="w-20 h-24 text-gray-500"
                            >
                                <path
                                    stroke-linecap="round" 
                                    stroke-linejoin="round"
                                    d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zM80 288c0-17.7 14.3-32 32-32h96c17.7 0 32 14.3 32 32v16l44.9-29.9c2-1.3 4.4-2.1 6.8-2.1c6.8 0 12.3 5.5 12.3 12.3V387.7c0 6.8-5.5 12.3-12.3 12.3c-2.4 0-4.8-.7-6.8-2.1L240 368v16c0 17.7-14.3 32-32 32H112c-17.7 0-32-14.3-32-32V288z">
                                </path>
                            </svg>
                        </div>                    
                    </div>
                </div>
            </div>
            <div className="after-detection-content">
                <div className='detection-factors'>
                    {/* Slider for adjusting the confidence value */}
                    <Typography id="non-linear-slider" gutterBottom>
                        {t('fiabilite')} : {value}
                    </Typography>
                    <Slider
                        value={value}
                        min={0}
                        step={0.01}
                        max={1}
                        aria-label="Small"
                        onChange={handleChange}
                        valueLabelDisplay="auto"
                        color="primary"
                        aria-labelledby="non-linear-slider"
                    />
                </div>
                {/*<div className="save-buttons">
                    Button group for save and download options
                    <ButtonGroup ripple={true} color="blue">
                        <Button>{t('save')}</Button>
                        <Button>{t('download')}</Button>
                    </ButtonGroup>
                </div>*/}
            </div>
        </div>
        {/* Footer component */}
        <Footer />
    </div>
  );
}

export default Video;
