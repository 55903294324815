import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import Header from './Header';
import HeaderConnected from './HeaderConnected';
import Footer from './Footer';
import { useTheme } from '../contexts/ThemeContext';
import { useTranslation } from 'react-i18next';
import '../stylesheets/saved_file.css'; // Importing the stylesheet for the Saved Files page
import axios from 'axios';
import Cookies from 'js-cookie';
import {
    List,
    ListItem,
    ListItemSuffix,
    Card,
    IconButton,
} from "@material-tailwind/react";
import { Button } from 'flowbite-react';

// TrashIcon component for delete functionality 
function TrashIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="h-5 w-5"
        >
            {/* SVG path for the trash icon */}
            <path
                fillRule="evenodd"
                d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 11-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                clipRule="evenodd"
            />
        </svg>
    );
}


// RenameIcon component for rename functionality
function RenameIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="h-5 w-5"
        >
            <path
                d="M16.474 2.39l5.137 5.137c.345.345.345.901 0 1.246l-10.24 10.24c-.18.18-.414.305-.663.368l-5.474 1.368a.752.752 0 01-.92-.92l1.368-5.474c.063-.249.188-.483.368-.663l10.24-10.24c.345-.345.901-.345 1.246 0zm-3.858 6.42l-8.826 8.826-1.09 4.358 4.358-1.09 8.826-8.826-3.268-3.268zm5.65-5.65l-2.442 2.441 3.268 3.268 2.442-2.441-3.268-3.268z"
            />
        </svg>
    );
}



// SavedFile Component
function SavedFile() {
    // State variables for files and loading indicator
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [folders, setFolders] = useState([]);
    const [currentFolder, setCurrentFolder] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFolder, setSelectedFolder] = useState(null); // State for selected folder
    const { darkMode, toggleDarkMode } = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Check if the user is logged in to display the correct Header/navbar
    /*useEffect(() => {
        const loggedUser = Cookies.get('loggedUser');
        if (!loggedUser) {
            setIsLoggedIn(false);
            return;
        }
    
        const fetchData = async () => {
            setLoading(true);
    
            try {
                const response = await axios.post('https://localhost:5000/api/files', {
                    loggedUser: loggedUser,
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                setFiles(response.data);
            } catch (error) {
                console.error('Error fetching files: ' + error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);*/
    

    useEffect(() => {
        fetchFoldersAndFiles();
    }, []);

    const fetchFoldersAndFiles = async () => {
        const loggedUser = Cookies.get('loggedUser');
    
        try {
          const foldersResponse = await axios.post('https://server.raccoonvision.com/api/userFolders', { loggedUser });
          setFolders(foldersResponse.data);
    
          const filesResponse = await axios.post('https://server.raccoonvision.com/api/files', { loggedUser });
          setFiles(filesResponse.data);
        } catch (error) {
          console.error('Error fetching folders or files:', error);
        }
      };

    const createFolder = (name) => {
        axios.post('https://server.raccoonvision.com/api/folders', {
          name: name,
          parentFolderId: currentFolder,
          loggedUser: Cookies.get('loggedUser'),
        })
        .then(response => {
          setFolders([...folders, response.data]);
          return fetchFoldersAndFiles();
        })
       .catch(error => console.error('Error creating folder:', error));
    };

    const moveFile = (fileId, folderId) => {
        axios.post('https://server.raccoonvision.com/api/moveFile', { 
            fileId : fileId, 
            folderId : folderId,
        })
        .then(response => {
            setFiles(files.map(file => file.idFichier === fileId ? { ...file, folderId } : file));
            return fetchFoldersAndFiles();
        })
        .catch(error => console.error('Error moving file:', error));
    };

    const deleteFile = async (fileId) => {
        try {
          await axios.delete(`https://server.raccoonvision.com/api/files/${fileId}`);
          await fetchFoldersAndFiles();
        } catch (error) {
          console.error('Error deleting file:', error);
        }
      };

    const renameFile = (fileId, newName) => {
        axios.put(`https://server.raccoonvision.com/api/files/${fileId}`, { name: newName })
            .then(() => {
                setFiles(files.map(file => file.idFichier === fileId ? { ...file, name: newName } : file));
                return fetchFoldersAndFiles();
            })
            .catch(error => console.error('Error renaming file:', error));
    };

    const deleteFolder = (folderId) => {
        axios.delete(`https://server.raccoonvision.com/api/folders/${folderId}`)
            .then(() => {
                setFolders(folders.filter(folder => folder.idDossier !== folderId));
                return fetchFoldersAndFiles();
            })
            .catch(error => console.error('Error deleting folder:', error));
    };

    const renameFolder = (folderId, newName) => {
        axios.put(`https://server.raccoonvision.com/api/folders/${folderId}`, { name: newName })
            .then(() => {
                setFolders(folders.map(folder => folder.idDossier === folderId ? { ...folder, name: newName } : folder));
                return fetchFoldersAndFiles();
            })
            .catch(error => console.error('Error renaming folder:', error));
    };

    const getFolderIdByName = (name) => {
        const folder = folders.find(folder => folder.nomD === name);
        return folder ? folder.idDossier : null;
    };

    const handleFileDoubleClick = (idFichier) => {
        navigate(`/saved/images/${idFichier}`);
    };

    const handleFolderDoubleClick = (idDossier)=>{
        navigate(`/saved/${idDossier}`);
    };

    const isLoggedIn = Cookies.get('loggedUser') !== undefined;
    if (!isLoggedIn) {
        return <Navigate to="/connexion" />;
    }

    return (
        <div className={`App ${darkMode ? 'dark' : 'light'}`}>
            {isLoggedIn ? <HeaderConnected /> : <Header />}
            <main>
                <h1 className="title">{t('saved_detec')}</h1>
                        <div className="folder-container">
                            {folders.map(folder => (
                                <div key={folder.idDossier} className="folder-item" onDoubleClick={() => handleFolderDoubleClick(folder.idDossier)}>
                                    <div className="folder-icon" onClick={() => handleFolderDoubleClick(folder.idDossier)}>📁</div>
                                    <span onClick={() => handleFolderDoubleClick(folder.idDossier)}>{folder.nomD}</span>
                                    <div className='folder-actions'>
                                        <button 
                                            onClick={() => deleteFolder(folder.idDossier)} 
                                            className="delete-button"
                                        >
                                            <TrashIcon />
                                        </button>
                                        <button 
                                            color='white'
                                            className="rename-button" 
                                            onClick={() => {
                                                const newName = prompt(t('new_name'), folder.nomD);
                                                if (newName) {
                                                    renameFolder(folder.idDossier, newName);
                                                }
                                            }}
                                        >
                                            <RenameIcon />
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <Button color="blue" onClick={() => {
                            const folderName = prompt(t('new_name'));
                            if (folderName) createFolder(folderName);
                        }}>{t('add')}</Button>

                        <div className="file-container">
                            {files.map(file => (
                                <div key={file.idFichier} className="file-item" onDoubleClick={() => handleFileDoubleClick(file.idFichier)}>
                                    <div className="file-icon" onClick={() => handleFileDoubleClick(file.idFichier)}>📄</div>
                                    <span onClick={() => handleFileDoubleClick(file.idFichier)}>{file.nomF}</span>
                                    <div className="file-actions">
                                        <button 
                                            onClick={() => deleteFile(file.idFichier)} 
                                            className="delete-button"
                                        >
                                            <TrashIcon />
                                        </button>
                                        <button 
                                            className="rename-button" 
                                            onClick={() => {
                                                const newName = prompt(t('file_name'), file.nomF);
                                                if (newName) {
                                                    renameFile(file.idFichier, newName);
                                                }
                                            }}
                                        >
                                            <RenameIcon />
                                        </button>
                                        <button className="move-button" onClick={() => {
                                            const folderName = prompt(t('destination'));
                                            if (folderName) {
                                                const folderId = folders.find(f => f.nomD === folderName)?.idDossier;
                                                if (folderId) {
                                                    moveFile(file.idFichier, folderId);
                                                } else {
                                                    alert(t('not_found'));
                                                }
                                            }
                                        }}>
                                            {t('move')}
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
            </main>
            <Footer />
        </div>
    );
}


export default SavedFile;
