import React, { useEffect } from 'react';
import Cookies from 'js-cookie';

import { Navigate } from 'react-router-dom';

const Deconnexion = () => {
  useEffect(() => {
    Cookies.remove('loggedUser');
  }, []);
  return <Navigate to="/" />;
};

export default Deconnexion;