import React from 'react';
import Cookies from 'js-cookie';
import Header from './Header';
import HeaderConnected from './HeaderConnected';
import Footer from './Footer';
import { useTheme } from '../contexts/ThemeContext';
import { useTranslation } from 'react-i18next';
import '../stylesheets/error404.css';

const Error404 = () => {
    const loggedUser = Cookies.get('loggedUser');
    const isLoggedIn = loggedUser !== undefined;

    const { darkMode } = useTheme();
    const { t } = useTranslation();

    return (
        <div className={`App ${darkMode ? 'dark' : ''}`}>
            {isLoggedIn ? <HeaderConnected /> : <Header />}
            <div className="main-content">
                <div>
                    <h1>{t('error404_message')}</h1>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Error404;