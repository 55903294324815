// Importing necessary dependencies and components
import React from 'react';
import Cookies from 'js-cookie';
import Header from './Header';
import HeaderConnected from './HeaderConnected';
import Footer from './Footer';
import { useTheme } from '../contexts/ThemeContext';
import { useTranslation } from 'react-i18next';
import '../stylesheets/legal-docs.css';

// Functional component for the Documentation page
function Documentation() {
  // Checking if a user is logged in by retrieving the 'loggedUser' from session storage
  const loggedUser = Cookies.get('loggedUser');
  const isLoggedIn = loggedUser !== undefined;

  const { darkMode, toggleDarkMode } = useTheme();
  const { t } = useTranslation();

  return (
    <div className={`App ${darkMode ? 'dark' : 'light'}`}>
      {/* Conditional rendering of Header based on user login status */}
      {isLoggedIn ? <HeaderConnected /> : <Header />}

      {/* Main content section for legal documentation */}
      <div className="content-docs flex justify-center flex-col">
        <h1>{t('explications_modeles')}</h1>
        
        {/* Information about YOLOv8 model */}
        <h2>YOLOv8: </h2>
        <p>{t('yolo')}</p>

        {/* Information about Detectron2 model */}
        <h2>Detectron2: </h2>
        <p>{t('detectron')}</p>

        {/* Information about EfficientDet model */}
        <h2>EfficientDet: </h2>
        <p>{t('efficientdet')}</p>

        {/* Information about SSD_MobileNet model */}
        <h2>SSD_MobileNet: </h2>
        <p>{t('ssd')}</p>

        {/* User guide section */}
        <h1>{t('user_guide')}</h1>

        {/* Introduction for first-time visitors */}
        <p>{t('phrase_user_guide')}</p>

        {/* Instructions for first-time visitors */}
        <h2>{t('first_visit')}</h2>
        <p>{t('phrase_first_visit')}</p>

        {/* Instructions for returning users */}
        <h2>{t('deja_inscrit')}</h2>
        <p>{t('phrase_deja_inscrit')}</p>

        {/* Steps for using RaccoonVision */}
        <h2>{t('utilisation')}</h2>
        <ol className="list-decimal">
          <li>{t('utilisation1')}</li>
          <li>{t('utilisation2')}</li>
          <li>{t('utilisation3')}</li>
          <li>{t('utilisation4')}</li>
        </ol><h1>{t('presentation')}</h1>
        <h2>TorchVision</h2>
        <ul className="list-disc">
          <li>{t('avion')}</li>
          <li>{t('ballon')}</li>
          <li>{t('banane')}</li>
          <li>{t('banc')}</li>
          <li>{t('bateau')}</li>
          <li>{t('bol')}</li>
          <li>{t('bouche')}</li>
          <li>{t('bouteille')}</li>
          <li>{t('brocoli')}</li>
          <li>{t('bus')}</li>
          <li>{t('camion')}</li>
          <li>{t('canape')}</li>
          <li>{t('carotte')}</li>
          <li>{t('kite')}</li>
          <li>{t('chaise')}</li>
          <li>{t('chat')}</li>
          <li>{t('cheval')}</li>
          <li>{t('chien')}</li>
          <li>{t('ciseaux')}</li>
          <li>{t('clavier')}</li>
          <li>{t('couteau')}</li>
          <li>{t('cravate')}</li>
          <li>{t('cuillere')}</li>
          <li>{t('donut')}</li>
          <li>{t('ecran_tv')}</li>
          <li>{t('elephant')}</li>
          <li>{t('evier')}</li>
          <li>{t('traffic_light')}</li>
          <li>{t('four')}</li>
          <li>{t('fourchette')}</li>
          <li>{t('girafe')}</li>
          <li>{t('gateau')}</li>
          <li>{t('horloge')}</li>
          <li>{t('hotdog')}</li>
          <li>{t('lit')}</li>
          <li>{t('livre')}</li>
          <li>{t('microondes')}</li>
          <li>{t('moto')}</li>
          <li>{t('mouton')}</li>
          <li>{t('oiseau')}</li>
          <li>{t('orange')}</li>
          <li>{t('ordi')}</li>
          <li>{t('peluche')}</li>
          <li>{t('stop')}</li>
          <li>{t('parapluie')}</li>
          <li>{t('parcmetre')}</li>
          <li>{t('personne')}</li>
          <li>{t('pizza')}</li>
          <li>{t('plante')}</li>
          <li>{t('pomme')}</li>
          <li>{t('portable')}</li>
          <li>{t('raquette')}</li>
          <li>{t('frigo')}</li>
          <li>{t('sac_main')}</li>
          <li>{t('sac_dos')}</li>
          <li>{t('sandwich')}</li>
          <li>{t('skateboard')}</li>
          <li>{t('skis')}</li>
          <li>{t('souris')}</li>
          <li>{t('surf')}</li>
          <li>{t('tasse')}</li>
          <li>{t('toilettes')}</li>
          <li>{t('train')}</li>
          <li>{t('remote')}</li>
          <li>{t('tel_portable')}</li>
          <li>{t('vache')}</li>
          <li>{t('valise')}</li>
          <li>{t('vase')}</li>
          <li>{t('vin')}</li>
          <li>{t('voiture')}</li>
          <li>{t('velo')}</li>
          <li>{t('zebre')}</li>
        </ul>
        <h2>Ssd Mobilenet</h2>
        <ul className="list-disc">
          <li>{t('avion')}</li>
          <li>{t('ballon')}</li>
          <li>{t('banane')}</li>
          <li>{t('banc')}</li>
          <li>{t('bateau')}</li>
          <li>{t('batte_baseball')}</li>
          <li>{t('bol')}</li>
          <li>{t('bouche')}</li>
          <li>{t('bouteille')}</li>
          <li>{t('brocoli')}</li>
          <li>{t('brosse_dent')}</li>
          <li>{t('bus')}</li>
          <li>{t('camion')}</li>
          <li>{t('canape')}</li>
          <li>{t('carotte')}</li>
          <li>{t('kite')}</li>
          <li>{t('chaise')}</li>
          <li>{t('chat')}</li>
          <li>{t('cheval')}</li>
          <li>{t('chien')}</li>
          <li>{t('ciseaux')}</li>
          <li>{t('clavier')}</li>
          <li>{t('couteau')}</li>
          <li>{t('cravate')}</li>
          <li>{t('cuillere')}</li>
          <li>{t('donut')}</li>
          <li>{t('ecran_tv')}</li>
          <li>{t('elephant')}</li>
          <li>{t('evier')}</li>
          <li>{t('traffic_light')}</li>
          <li>{t('four')}</li>
          <li>{t('fourchette')}</li>
          <li>{t('gants_baseball')}</li>
          <li>{t('girafe')}</li>
          <li>{t('gateau')}</li>
          <li>{t('grille_pain')}</li>
          <li>{t('horloge')}</li>
          <li>{t('hotdog')}</li>
          <li>{t('lit')}</li>
          <li>{t('livre')}</li>
          <li>{t('microondes')}</li>
          <li>{t('moto')}</li>
          <li>{t('mouton')}</li>
          <li>{t('oiseau')}</li>
          <li>{t('orange')}</li>
          <li>{t('ordi')}</li>
          <li>{t('ours')}</li>
          <li>{t('peluche')}</li>
          <li>{t('stop')}</li>
          <li>{t('parapluie')}</li>
          <li>{t('parcmetre')}</li>
          <li>{t('personne')}</li>
          <li>{t('pizza')}</li>
          <li>{t('plante')}</li>
          <li>{t('pomme')}</li>
          <li>{t('portable')}</li>
          <li>{t('raquette')}</li>
          <li>{t('frigo')}</li>
          <li>{t('sac_main')}</li>
          <li>{t('sac_dos')}</li>
          <li>{t('sandwich')}</li>
          <li>{t('seche_cheveux')}</li>
          <li>{t('skateboard')}</li>
          <li>{t('skis')}</li>
          <li>{t('snowboard')}</li>
          <li>{t('souris')}</li>
          <li>{t('surf')}</li>
          <li>{t('table')}</li>
          <li>{t('tasse')}</li>
          <li>{t('toilettes')}</li>
          <li>{t('train')}</li>
          <li>{t('remote')}</li>
          <li>{t('tel_portable')}</li>
          <li>{t('vache')}</li>
          <li>{t('valise')}</li>
          <li>{t('vase')}</li>
          <li>{t('vin')}</li>
          <li>{t('voiture')}</li>
          <li>{t('velo')}</li>
          <li>{t('zebre')}</li>
        </ul>
        <h2>Detectron2</h2>
        <p>{t('phrase_detectron')}</p>
        <ul className="list-disc">
          <li>{t('avion')}</li>
          <li>{t('ballon')}</li>
          <li>{t('banane')}</li>
          <li>{t('banc')}</li>
          <li>{t('bateau')}</li>
          <li>{t('batte_baseball')}</li>
          <li>{t('bol')}</li>
          <li>{t('bouche')}</li>
          <li>{t('bouteille')}</li>
          <li>{t('brocoli')}</li>
          <li>{t('brosse_dent')}</li>
          <li>{t('bus')}</li>
          <li>{t('camion')}</li>
          <li>{t('canape')}</li>
          <li>{t('carotte')}</li>
          <li>{t('kite')}</li>
          <li>{t('chaise')}</li>
          <li>{t('chat')}</li>
          <li>{t('cheval')}</li>
          <li>{t('chien')}</li>
          <li>{t('ciel')}</li>
          <li>{t('ciseaux')}</li>
          <li>{t('clavier')}</li>
          <li>{t('cloture')}</li>
          <li>{t('coureur')}</li>
          <li>{t('couteau')}</li>
          <li>{t('cravate')}</li>
          <li>{t('cuillere')}</li>
          <li>{t('donut')}</li>
          <li>{t('ecran_tv')}</li>
          <li>{t('elephant')}</li>
          <li>{t('evier')}</li>
          <li>{t('traffic_light')}</li>
          <li>{t('four')}</li>
          <li>{t('fourchette')}</li>
          <li>{t('frisbee')}</li>
          <li>{t('gants_baseball')}</li>
          <li>{t('girafe')}</li>
          <li>{t('gateau')}</li>
          <li>{t('grille_pain')}</li>
          <li>{t('horloge')}</li>
          <li>{t('hotdog')}</li>
          <li>{t('lit')}</li>
          <li>{t('livre')}</li>
          <li>{t('microondes')}</li>
          <li>{t('moto')}</li>
          <li>{t('mouton')}</li>
          <li>{t('mur')}</li>
          <li>{t('oiseau')}</li>
          <li>{t('orange')}</li>
          <li>{t('ordi')}</li>
          <li>{t('ours')}</li>
          <li>{t('peluche')}</li>
          <li>{t('panneau')}</li>
          <li>{t('stop')}</li>
          <li>{t('parapluie')}</li>
          <li>{t('parcmetre')}</li>
          <li>{t('personne')}</li>
          <li>{t('pizza')}</li>
          <li>{t('plante')}</li>
          <li>{t('pomme')}</li>
          <li>{t('poteau')}</li>
          <li>{t('portable')}</li>
          <li>{t('raquette')}</li>
          <li>{t('frigo')}</li>
          <li>{t('route')}</li>
          <li>{t('sac_main')}</li>
          <li>{t('sac_dos')}</li>
          <li>{t('sandwich')}</li>
          <li>{t('seche_cheveux')}</li>
          <li>{t('skateboard')}</li>
          <li>{t('skis')}</li>
          <li>{t('snowboard')}</li>
          <li>{t('souris')}</li>
          <li>{t('surf')}</li>
          <li>{t('table')}</li>
          <li>{t('tasse')}</li>
          <li>{t('toilettes')}</li>
          <li>{t('train')}</li>
          <li>{t('trottoir')}</li>
          <li>{t('remote')}</li>
          <li>{t('tel_portable')}</li>
          <li>{t('television')}</li>
          <li>{t('terrain')}</li>
          <li>{t('vache')}</li>
          <li>{t('valise')}</li>
          <li>{t('vase')}</li>
          <li>{t('vegetation')}</li>
          <li>{t('vin')}</li>
          <li>{t('voiture')}</li>
          <li>{t('velo')}</li>
          <li>{t('zebre')}</li>
        </ul>
        <h2>YoloV8</h2>
        <ul className="list-disc">
          <li>{t('avion')}</li>
          <li>{t('ballon')}</li>
          <li>{t('banane')}</li>
          <li>{t('banc')}</li>
          <li>{t('bateau')}</li>
          <li>{t('batte_baseball')}</li>
          <li>{t('bol')}</li>
          <li>{t('bouche')}</li>
          <li>{t('bouteille')}</li>
          <li>{t('brocoli')}</li>
          <li>{t('brosse_dent')}</li>
          <li>{t('bus')}</li>
          <li>{t('camion')}</li>
          <li>{t('canape')}</li>
          <li>{t('carotte')}</li>
          <li>{t('kite')}</li>
          <li>{t('chaise')}</li>
          <li>{t('chat')}</li>
          <li>{t('cheval')}</li>
          <li>{t('chien')}</li>
          <li>{t('ciel')}</li>
          <li>{t('ciseaux')}</li>
          <li>{t('clavier')}</li>
          <li>{t('cloture')}</li>
          <li>{t('coureur')}</li>
          <li>{t('couteau')}</li>
          <li>{t('cravate')}</li>
          <li>{t('cuillere')}</li>
          <li>{t('donut')}</li>
          <li>{t('ecran_tv')}</li>
          <li>{t('elephant')}</li>
          <li>{t('evier')}</li>
          <li>{t('traffic_light')}</li>
          <li>{t('four')}</li>
          <li>{t('fourchette')}</li>
          <li>{t('frisbee')}</li>
          <li>{t('gants_baseball')}</li>
          <li>{t('girafe')}</li>
          <li>{t('gateau')}</li>
          <li>{t('grille_pain')}</li>
          <li>{t('horloge')}</li>
          <li>{t('hotdog')}</li>
          <li>{t('lit')}</li>
          <li>{t('livre')}</li>
          <li>{t('microondes')}</li>
          <li>{t('moto')}</li>
          <li>{t('mouton')}</li>
          <li>{t('mur')}</li>
          <li>{t('oiseau')}</li>
          <li>{t('orange')}</li>
          <li>{t('ordi')}</li>
          <li>{t('ours')}</li>
          <li>{t('peluche')}</li>
          <li>{t('panneau')}</li>
          <li>{t('stop')}</li>
          <li>{t('parapluie')}</li>
          <li>{t('parcmetre')}</li>
          <li>{t('personne')}</li>
          <li>{t('pizza')}</li>
          <li>{t('plante')}</li>
          <li>{t('pomme')}</li>
          <li>{t('poteau')}</li>
          <li>{t('portable')}</li>
          <li>{t('raquette')}</li>
          <li>{t('frigo')}</li>
          <li>{t('route')}</li>
          <li>{t('sac_main')}</li>
          <li>{t('sac_dos')}</li>
          <li>{t('sandwich')}</li>
          <li>{t('seche_cheveux')}</li>
          <li>{t('skateboard')}</li>
          <li>{t('skis')}</li>
          <li>{t('snowboard')}</li>
          <li>{t('souris')}</li>
          <li>{t('surf')}</li>
          <li>{t('table')}</li>
          <li>{t('tasse')}</li>
          <li>{t('toilettes')}</li>
          <li>{t('train')}</li>
          <li>{t('trottoir')}</li>
          <li>{t('remote')}</li>
          <li>{t('tel_portable')}</li>
          <li>{t('television')}</li>
          <li>{t('terrain')}</li>
          <li>{t('vache')}</li>
          <li>{t('valise')}</li>
          <li>{t('vase')}</li>
          <li>{t('vegetation')}</li>
          <li>{t('vin')}</li>
          <li>{t('voiture')}</li>
          <li>{t('velo')}</li>
          <li>{t('zebre')}</li>
        </ul>
      </div>

      {/* Footer component */}
      <Footer />
    </div>
  );
}

// Exporting the 'Documentation' component as the default export
export default Documentation;