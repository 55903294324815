// Importing necessary dependencies and components
import React from 'react';
import { Helmet } from 'react-helmet'; // Importing Helmet for managing document head metadata
import imgLogo from "../images/logo-simple.png"; // Importing the logo image
import ToggleDarkMode from './ToggleDarkMode';
import LanguageSelector from './LanguageSelector';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../contexts/ThemeContext';
import '../stylesheets/header.css'; // Importing the stylesheet for the header
import {
  Navbar,
  Collapse,
  Typography,
  Button,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react"; // Importing components from the Material Tailwind CSS library
import {
  ChevronDownIcon,
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline"; // Importing icons from Heroicons
import {
  ComputerDesktopIcon,
  PhotoIcon,
  FilmIcon,
} from "@heroicons/react/24/solid"; // Importing solid icons from Heroicons


// Component for rendering items in the dropdown menu
function NavListMenu() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);

  const { darkMode, toggleDarkMode } = useTheme();
  const { t } = useTranslation();

  // Array of navigation items for the dropdown menu
  const navListMenuItems = [
    {
      title: t('detec_image'),
      description: t('rediscover'),
      icon: PhotoIcon,
      link: "/image",
    },
    {
      title: t('detec_video'),
      description: t('matters'),
      icon: FilmIcon,
      link: "/video",
    },
    {
      title: t('detec_webcam'),
      description: t('behind'),
      icon: ComputerDesktopIcon,
      link: "/webcam",
    },
  ];
  
  // Mapping through the navigation items to generate menu items
  const renderItems = navListMenuItems.map(({ icon, title, description, link }, key) => (
    <div key={key}>
      <a href={link} className="no-underline">
        <MenuItem className={`flex items-center gap-3 rounded-lg transition-colors duration-200 bg-white text-gray-900 hover:border-[#f8e049] hover:bg-gray-100`}>
          <div className={`flex items-center justify-center rounded-lg p-2 bg-blue-gray-50`}>
            {React.createElement(icon, {
              strokeWidth: 2,
              className: `h-6 w-6 text-gray-900`,
            })}
          </div>
          <div>
            <Typography
              variant="h6"
              className={`flex items-center text-sm font-bold text-gray-900`}
            >
              {title}
            </Typography>
            <Typography
              variant="paragraph"
              className={`text-xs font-medium text-blue-gray-500`}
            >
              {description}
            </Typography>
          </div>
        </MenuItem>
      </a>
    </div>
  ));

  return (
    <React.Fragment>
      <Menu
        open={isMenuOpen}
        handler={setIsMenuOpen}
        offset={{ mainAxis: 20 }}
        placement="bottom"
        allowHover={true}
      >
        <MenuHandler>
          <Typography as="div" variant="h6" className="font-medium">
            <ListItem
              className="flex items-center gap-2 py-2 pr-4 font-medium text-gray-900"
              selected={isMenuOpen || isMobileMenuOpen}
              onClick={() => setIsMobileMenuOpen((cur) => !cur)}
            >
              {t('detections')}
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`hidden h-3 w-3 transition-transform lg:block ${isMenuOpen ? 'rotate-180' : ''}`}
              />
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`block h-3 w-3 transition-transform lg:hidden ${isMobileMenuOpen ? 'rotate-180' : ''}`}
              />
            </ListItem>
          </Typography>
        </MenuHandler>
        <MenuList className={`hidden max-w-screen-xl rounded-xl lg:block bg-white text-gray-900`}>
          <ul className="grid grid-cols-3 gap-y-2 outline-none outline-0">
            {renderItems}
          </ul>
        </MenuList>
      </Menu>
      <div className="block lg:hidden">
        <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
      </div>
    </React.Fragment>
  );
}

// Component for rendering the main navigation list
function NavList() {

  const { t } = useTranslation();
  
  return (
    <List className="mt-4 mb-6 p-0 lg:mt-0 lg:mb-0 lg:flex-row lg:p-1">
      {/* Render the dropdown menu */}
      <NavListMenu />
      {/* Documentation link */}
      <Typography
        as="a"
        href="/documentation"
        variant="h6"
        color="blue-gray"
        className="font-medium"
      >
        <ListItem className="flex items-center gap-2 py-2 pr-4">
          {t('doc')}
        </ListItem>
      </Typography>
    </List>
  );
}

// Main Header component
export function HeaderLight() {
  const [openNav, setOpenNav] = React.useState(false);

  const { t } = useTranslation();
  const { darkMode } = useTheme();

  // Close mobile navigation when window width is greater than or equal to 960px
  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false),
    );
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>RaccoonVision</title>
        <meta name="description" content="The RaccoonVision website is designed to enable anyone, with or without experience of AI models, to choose from a selection of artificial intelligence models for object detection in images, videos and webcams." />
        <meta name="keywords" content="detection, image, video, webcam, raccoonvision, AI, IT" />
        <link rel="icon" type="image/png" href={imgLogo} />
      </Helmet>
      <Navbar className={`mx-auto max-w-screen-xl px-4 py-2 sticky bg-white`}>
        <div className="flex items-center justify-between text-blue-gray-900">
          <Typography
            as="a"
            href="/"
            variant="h6"
            className="mr-4 py-1.5 lg:ml-2"
          >
            RaccoonVision
          </Typography>
          <div className="hidden lg:block">
            <NavList />
          </div>
          <ToggleDarkMode />
          <LanguageSelector />
          <div className="hidden gap-2 lg:flex">
            <a href="/connexion">
              <Button variant="filled" size="md" color="blue">
                {t('login')}
              </Button>
            </a>
          </div>
          <IconButton
            variant="text"
            color="blue-gray"
            className="lg:hidden"
            onClick={() => setOpenNav(!openNav)}
          >
            {openNav ? (
              <XMarkIcon className="h-6 w-6" strokeWidth={2} />
            ) : (
              <Bars3Icon className="h-6 w-6" strokeWidth={2} />
            )}
          </IconButton>
        </div>
        <Collapse open={openNav}>
          <NavList />
          <a href="/connexion">
            <div className="flex w-full flex-nowrap items-center gap-2 lg:hidden">
              <Button variant="filled" size="md" color="blue" fullWidth>
                {t('login')}
              </Button>
            </div>
          </a>
        </Collapse>
      </Navbar>
    </React.Fragment>
  );
}

// Default export for the Header component
export default HeaderLight;
