import React, { useState, useEffect } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import openedEye from "../images/eye-open.png";
import closedEye from "../images/eye-closed.png";
import axios from 'axios';
import Cookies from 'js-cookie';
import Header from "./HeaderLight";
import { useTheme } from '../contexts/ThemeContext';
import { useTranslation } from 'react-i18next';
import '../stylesheets/form.css';
import {
    Card,
    Input,
    Checkbox,
    Button,
    IconButton,
    Typography,
} from "@material-tailwind/react";

// SignUpCard Component
function SignUpCard() {

    // State variables for form fields, loading state, error/success message, etc.
    const [prenom, setPrenom] = useState('');
    const [nom, setNom] = useState('');
    const [dateN, setDateN] = useState('');
    const [mail, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const { t } = useTranslation();
    const { darkMode } = useTheme();


    const loggedUser = Cookies.get('loggedUser');
    const isLoggedIn = loggedUser !== undefined;

    if (isLoggedIn) {
        return <Navigate to="/Error404" />;
    }

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if all required fields are filled
        if (!prenom || !nom || !dateN || !mail || !password) {
            setMessage('Please enter correct information to register.');
            return;
        }

        // Check if the password meets the criteria
        if (password.length < 8 || !/[A-Z]/.test(password) || !/[a-z]/.test(password) || !/\d/.test(password)) {
            setMessage('Please enter a valid password. It should be at least 8 characters long and include at least one uppercase letter, one lowercase letter, and one number.');
            return;
        }

        // Check if the checkbox is checked
        if (!agreeTerms) {
            setMessage('Please accept the Terms and Conditions.');
            return;
        }

        // Set loading state to true and attempt to register the user
        setLoading(true);

        try {
            const response = await axios.post('https://server.raccoonvision.com/api/register', {
                prenom: prenom,
                nom: nom,
                dateN: dateN,
                mail: mail,
                mdp: password,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            // Handle the response status and navigate accordingly
            if (response.status === 200) {
                setMessage('You successfully signed up');
                navigate('/connexion');
            } else {
                setMessage('Échec de la connexion');
            }
        } catch (error) {
            setMessage('Error during registration: ' + error.message);
        } finally {
            // Set loading state back to false
            setLoading(false);
        }
    };

    // JSX structure for the SignUpCard component
    return (
        <Card className="card-loginsignup" color="transparent" shadow={true}>
            <Typography variant="h4" color="blue-gray">
                {t('signup')}
            </Typography>
            <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96" onSubmit={handleSubmit}>
                {/* Form fields for user information */}
                <div className="mb-1 flex flex-col gap-6">
                    <Typography variant="h6" color="blue-gray" className="-mb-3">
                        {t('prenom')}
                    </Typography>
                    <Input
                        type="text" 
                        id="prenom" 
                        value={prenom} 
                        onChange={(e) => setPrenom(e.target.value)}
                        size="lg"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                        className: "before:content-none after:content-none",
                        }}
                    />
                    <Typography variant="h6" color="blue-gray" className="-mb-3">
                        {t('nom')}
                    </Typography>
                    <Input
                        type="text" 
                        id="nom" 
                        value={nom} 
                        onChange={(e) => setNom(e.target.value)}
                        size="lg"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                        className: "before:content-none after:content-none",
                        }}
                    />
                    <Typography variant="h6" color="blue-gray" className="-mb-3">
                        {t('date_naiss')}
                    </Typography>
                    <Input
                            type="date"
                            id="dateN"
                            value={dateN}
                            onChange={(e) => setDateN(e.target.value)}
                            size="lg"
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{
                                className: "before:content-none after:content-none",
                            }}
                        />
                    
                    <Typography variant="h6" color="blue-gray" className="-mb-3">
                        {t('mail')}
                    </Typography>
                    <Input
                        type="email"
                        id="mail"
                        value={mail}
                        onChange={(e) => setEmail(e.target.value)}
                        size="lg"
                        placeholder="name@mail.com"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                        className: "before:content-none after:content-none",
                        }}
                    />
                    <Typography variant="h6" color="blue-gray" className="-mb-3">
                        {t('mdp')}
                    </Typography>
                    <Typography
                        variant="small"
                        color="gray"
                        className="mt-2 flex items-center gap-1 font-normal"
                    >
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="-mt-px h-8 w-8"
                        >
                        <path
                            fillRule="evenodd"
                            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                            clipRule="evenodd"
                        />
                        </svg>
                        {t('conditions_mdp')}
                    </Typography>
                    <div className="flex flex-row">
                        <Input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            size="lg"
                            placeholder="********"
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{
                            className: "before:content-none after:content-none",
                            }}
                        />
                        <IconButton
                            color="black"
                            ripple="light"
                            onClick={() => setShowPassword(!showPassword)}
                            className="ml-2 mt-0.5"
                        >
                            {showPassword ? <img src={openedEye} alt="eye opened" /> : <img src={closedEye} alt="eye closed" />}
                        </IconButton>
                    </div>             
                </div>
                {/* Checkbox for agreeing to terms and conditions */}
                <Checkbox
                    checked={agreeTerms}
                    onChange={() => setAgreeTerms(!agreeTerms)}
                    label={
                        <Typography
                        variant="small"
                        color="gray"
                        className="flex items-center font-normal"
                        >
                        {t('accept_phrase1')}&nbsp;
                        <a
                            href="/terms_of_use"
                            className="font-medium text-blue-500 hover:underline"
                        >
                            {t('accept_phrase2')}
                        </a>
                        </Typography>
                    }
                    containerProps={{ className: "-ml-2.5" }}
                />

                {/* Display error or success message */}
                {message && <p className="text-red-500">{message}</p>}
                
                {/* Submit button */}
                <Button type="submit" disabled={loading} className="mt-6 text-sm" fullWidth>
                    {t('signup')}
                </Button>
            </form>
        </Card>
    );
}

// LogInCard Component
function LogInCard() {
    // State variables for email, password, loading state, error/success message, and showPassword
    const [mail, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    // Navigate function from react-router-dom
    const navigate = useNavigate();
    const { t } = useTranslation();

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if both email and password are provided
        if (!mail || !password) {
            setMessage('Please enter correct information to log in.');
            return;
        }

        // Set loading state to true to indicate ongoing operation
        setLoading(true);

        try {
            // Attempt to log in with provided credentials
            const response = await axios.post('https://server.raccoonvision.com/api/login', {
                mail: mail,
                mdp: password,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            // Handle the response status and navigate accordingly
            if (response.status === 200) {
                setMessage('Login successful');
                // Store user information in session storage
                Cookies.set('loggedUser', mail, { expires: 30 });
                // Navigate to the home page
                navigate('/');
            } else {
                setMessage('Login failed');
            }
        } catch (error) {
            setMessage('Error during login: ' + error.message);
        } finally {
            // Set loading state back to false
            setLoading(false);
        }
    };

    // JSX structure for the LogInCard component
    return (
        <Card className="card-loginsignup" color="transparent" shadow={true}>
            <Typography variant="h4" color="blue-gray">
                {t('login2')}
            </Typography>
            <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96" onSubmit={handleSubmit}>
                {/* Form fields for email and password */}
                <div className="mb-1 flex flex-col gap-6">
                    <Typography variant="h6" color="blue-gray" className="-mb-3">
                        {t('mail')}
                    </Typography>
                    <Input
                        type="email"
                        id="mail"
                        value={mail}
                        onChange={(e) => setEmail(e.target.value)}
                        size="lg"
                        placeholder="name@mail.com"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                    />
                    <Typography variant="h6" color="blue-gray" className="-mb-3">
                        {t('mdp')}
                    </Typography>
                    <div className="flex flex-row">
                        <Input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            size="lg"
                            placeholder="********"
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{
                                className: "before:content-none after:content-none",
                            }}
                        />
                        {/* Toggle button for showing/hiding the password */}
                        <IconButton
                            color="black"
                            ripple="light"
                            onClick={() => setShowPassword(!showPassword)}
                            className="ml-2 mt-0.5"
                        >
                            {showPassword ? <img src={openedEye} alt="eye opened" /> : <img src={closedEye} alt="eye closed" />}
                        </IconButton>
                    </div>
                </div>
                {/* Display error or success message */}
                {message && <p className="text-red-500">{message}</p>}
                {/* Submit button */}
                <Button type="submit" className="mt-6 text-sm" fullWidth disabled={loading}>
                    {t('login2')}
                </Button>
            </form>
        </Card>
    );
}

// LogInSignUp Component
function LogInSignUp() {
    // State variable to manage the active panel (Log In or Sign Up)
    const [isRightPanelActive, setRightPanelActive] = useState(false);

    const { darkMode, toggleDarkMode } = useTheme();
    const { t } = useTranslation();

    // Function to handle panel toggling based on whether it's Sign Up or not
    const handlePanelToggle = (isSignUp) => {
        setRightPanelActive(isSignUp);
    };

    useEffect(() => {
        // Get the overlay container element
        const overlayContainer = document.querySelector('.overlay-container');

        // Event handler for Sign Up button click
        const handleSignUpClick = () => {
            setRightPanelActive(true);
        };

        // Event handler for Log In button click
        const handleSignInClick = () => {
            setRightPanelActive(false);
        };

        // Add event listeners to the buttons inside overlay-container
        const signUpButton = overlayContainer.querySelector('#signUp');
        const signInButton = overlayContainer.querySelector('#signIn');

        signUpButton.addEventListener('click', handleSignUpClick);
        signInButton.addEventListener('click', handleSignInClick);

        // Clean up event listeners on component unmount
        return () => {
            signUpButton.removeEventListener('click', handleSignUpClick);
            signInButton.removeEventListener('click', handleSignInClick);
        };
    }, []);

    // JSX structure for the LogInSignUp component
    return (
        <div className={`App`}>
            <Header />
            <div className="content-homepage">
                {/* Group of LogInCard and SignUpCard */}
                <div className="group-cards-loginsignup flex flex-col items-center justify-center sm:flex-row">
                    <LogInCard />
                    <SignUpCard />
                </div>
                {/* Container for the overlay panels (Log In and Sign Up) */}
                <div className={`container ${isRightPanelActive ? 'right-panel-active' : ''}`} id="container">
                    <div className="overlay-container hidden lg:block">
                        <div className="overlay">
                            {/* Left overlay panel for Log In */}
                            <div className="overlay-panel overlay-left">
                                <h1 className="font-bold text-white text-2xl">{t('welcomeback')}</h1>
                                <p className="text-lg">{t('info_perso')}</p>
                                <Button className="ghost" id="signUp" onClick={() => handlePanelToggle(false)}>{t('login2')}</Button>
                            </div>
                            {/* Right overlay panel for Sign Up */}
                            <div className="overlay-panel overlay-right">
                                <h1 className="font-bold text-white text-2xl">{t('meet')}</h1>
                                <p className="text-lg">{t('coordonnees')}</p>
                                <Button className="ghost" id="signIn" onClick={() => handlePanelToggle(true)}>{t('signup')}</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LogInSignUp;
