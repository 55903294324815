// Importing necessary dependencies and components
import React from 'react';
import { Typography } from "@material-tailwind/react"; // Importing Typography component from the Material Tailwind CSS library
import imgLogo from "../images/logo-long.png"; // Importing the logo image
import { useTranslation } from 'react-i18next';
import '../stylesheets/footer.css'; // Importing the stylesheet for the footer

// Functional component for the footer
export function Footer() {
  const { t } = useTranslation();

  return (
    // Footer container with styling classes
    <footer className={`w-full bg-white p-8 footer `}>
      {/* Container for flex layout with items centered and justified */}
      <div className="flex flex-row flex-wrap items-center justify-center gap-y-6 gap-x-12 text-center md:justify-between">
        {/* RaccoonVision logo */}
        <img src={imgLogo} alt="logo-simple" className="w-60 pt-2" />

        {/* Navigation links in an unordered list */}
        <ul className="flex flex-wrap items-center gap-y-2 gap-x-8">
          {/* Legal Notice link */}
          <li>
            <Typography
              as="a"
              href="/legal_notice"
              color="blue-gray"
              className="font-normal transition-colors hover:text-blue-500 focus:text-blue-500"
            >
              {t('mentions_legales')}
            </Typography>
          </li>
          {/* Terms of Use link */}
          <li>
            <Typography
              as="a"
              href="/terms_of_use"
              color="blue-gray"
              className="font-normal transition-colors hover:text-blue-500 focus:text-blue-500"
            >
              {t('cond_util')}
            </Typography>
          </li>
          {/* Privacy Policy link */}
          <li>
            <Typography
              as="a"
              href="/privacy_policy"
              color="blue-gray"
              className="font-normal transition-colors hover:text-blue-500 focus:text-blue-500"
            >
              {t('politique_conf')}
            </Typography>
          </li>
          {/* Feedback link with Twitter icon */}
          <li className="feedback-link">
            <a href="https://twitter.com/raccoonvision" className="feedback-element">
              {/* Feedback text */}
              <Typography
                color="blue-gray"
                padding-right="7px"
                className="feedback-text font-normal transition-colors hover:text-blue-500 focus:text-blue-500"
              >
                {t('feedback')}
              </Typography>
              {/* Twitter icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
                className="arrow-feedback h-4 w-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                />
              </svg>
            </a>
          </li>
        </ul>
      </div>
      
      {/* Horizontal line separator */}
      <hr className="my-6 border-blue-gray-50" />

      {/* Copyright text at the center */}
      <Typography color="blue-gray" className="text-center font-normal">
        Copyright &copy; 2024 RaccoonVision
      </Typography>
    </footer>
  );
}

// Exporting the 'Footer' component as the default export
export default Footer;
