import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en.json';
import translationFR from './locales/fr.json';
import translationAR from './locales/ar.json';
import translationCHI from './locales/chi.json';
import translationDE from './locales/de.json';
import translationES from './locales/es.json';
import translationGR from './locales/gr.json';
import translationHIN from './locales/hin.json';
import translationIT from './locales/it.json';
import translationJP from './locales/jp.json';
import translationKR from './locales/kr.json';
import translationNL from './locales/nl.json';
import translationPT from './locales/pt.json';
import translationRU from './locales/ru.json';
import translationTR from './locales/tr.json';
import translationVI from './locales/vi.json';
import translationWOL from './locales/wol.json';
import translationBR from './locales/br.json';
import translationCPF from './locales/cpf.json';

const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  ar: {
    translation: translationAR,
  },
  chi: {
    translation: translationCHI,
  },
  de: {
    translation: translationDE,
  },
  es: {
    translation: translationES,
  },
  gr: {
    translation: translationGR,
  },
  hin: {
    translation: translationHIN,
  },
  it: {
    translation: translationIT,
  },
  jp: {
    translation: translationJP,
  },
  kr: {
    translation: translationKR,
  },
  nl: {
    translation: translationNL,
  },
  pt: {
    translation: translationPT,
  },
  ru: {
    translation: translationRU,
  },
  tr: {
    translation: translationTR,
  },
  vi: {
    translation: translationVI,
  },
  wol: {
    translation: translationWOL,
  },
  br: {
    translation: translationBR,
  },
  cpf: {
    translation: translationCPF,
  },
};

const savedLanguage = localStorage.getItem('i18nextLng') || 'en';

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: savedLanguage,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

i18n.on('languageChanged', (lng) => {
  localStorage.setItem('i18nextLng', lng);
});

export default i18n;