import React, { useState, useEffect } from 'react';
import imgLogo from "../images/logo-simple.png";
import imgProfile from "../images/profile-picture.png";
import '../stylesheets/header.css';
import ToggleDarkMode from './ToggleDarkMode';
import LanguageSelector from './LanguageSelector';
import { useTheme } from '../contexts/ThemeContext';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Helmet } from 'react-helmet';
import { Modal } from 'flowbite-react';
import {
} from "@material-tailwind/react";
import {
    Avatar,
    Card,
    Input,
    Navbar,
    Collapse,
    Typography,
    IconButton,
    List,
    ListItem,
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
    Button,
} from "@material-tailwind/react";
import {
    ChevronDownIcon,
    Bars3Icon,
    XMarkIcon,
} from "@heroicons/react/24/outline";
import {
    ComputerDesktopIcon,
    PhotoIcon,
    FilmIcon,
    Cog6ToothIcon,
    InboxArrowDownIcon,
    PowerIcon,
} from "@heroicons/react/24/solid";


// ProfileMenu component with dropdown menu
function ProfileMenu({ openModal }) {

  // State to manage the open/closed status of the menu
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  // Function to close the menu
  const closeMenu = () => setIsMenuOpen(false);

  const { t } = useTranslation();
  const { darkMode, toggleDarkMode } = useTheme();

  return (
    <Menu
      open={isMenuOpen}
      handler={setIsMenuOpen}
      placement="bottom-end"
      allowHover={true}
      className={`${darkMode ? 'bg-gray-800 text-white' : 'bg-white text-blue-gray-900'}`}
    >
      <MenuHandler>
        <Button
          variant="text"
          color="blue-gray"
          className="flex items-center gap-1 rounded-full py-0.5 pr-2 pl-0.5"
        >
          <Avatar
            variant="circular"
            size="md"
            alt="user profile picture"
            className="border border-gray-900 p-0.5"
            src={imgProfile}
          />
          <ChevronDownIcon
            strokeWidth={2.5}
            className={`h-3 w-3 transition-transform ${isMenuOpen ? "rotate-180" : ""}`}
          />
        </Button>
      </MenuHandler>
      <MenuList className={`p-1 ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
        <React.Fragment>
          {/* Menu item for opening the profile modal */}
          <MenuItem
            onClick={() => {
              closeMenu();
              openModal();
            }}
            className={`flex items-center gap-2 rounded ${darkMode ? 'text-white hover:bg-gray-700 active:bg-gray-600' : 'text-blue-gray-900 hover:bg-gray-200 active:bg-gray-300'}`}
          >
            <Cog6ToothIcon
              className={`h-4 w-4 ${darkMode ? 'text-white' : ''}`}
              strokeWidth={2}
            />
            <Typography
              as="span"
              variant="small"
              className={`font-normal ${darkMode ? 'text-white' : 'text-blue-gray-900'}`}
            >
              {t('profile')}
            </Typography>
          </MenuItem>

          {/* Menu item for navigating to the saved page */}
          <a href="/saved" className="no-underline">
            <MenuItem
              onClick={closeMenu}
              className={`flex items-center gap-2 rounded ${darkMode ? 'text-white hover:bg-gray-700 active:bg-gray-600' : 'text-blue-gray-900 hover:bg-gray-200 active:bg-gray-300'}`}
            >
              <InboxArrowDownIcon
                className={`h-4 w-4 ${darkMode ? 'text-white' : ''}`}
                strokeWidth={2}
              />
              <Typography
                as="span"
                variant="small"
                className={`font-normal ${darkMode ? 'text-white' : 'text-blue-gray-900'}`}
              >
                {t('saved')}
              </Typography>
            </MenuItem>
          </a>

          {/* Horizontal line for separation */}
          <hr className={`my-2 ${darkMode ? 'border-gray-700' : 'border-blue-gray-50'}`} />

          {/* Menu item for logging out */}
          <a href="/deconnexion" className="no-underline">
            <MenuItem
              onClick={closeMenu}
              className={`flex items-center gap-2 rounded hover:bg-red-500/10 focus:bg-red-500/10 active:bg-red-500/10 ${darkMode ? 'text-white hover:bg-gray-700 active:bg-gray-600' : 'text-blue-gray-900 hover:bg-gray-200 active:bg-gray-300'}`}
            >
              <PowerIcon
                className={`h-4 w-4 text-red-500 ${darkMode ? 'text-white' : ''}`}
                strokeWidth={2}
              />
              <Typography
                as="span"
                variant="small"
                className={`font-normal ${darkMode ? 'text-white' : 'text-red-500'}`}
                color={darkMode ? 'inherit' : 'red'}
              >
                {t('logout')}
              </Typography>
            </MenuItem>
          </a>
        </React.Fragment>
      </MenuList>
    </Menu>
  );
}

function NavListMenu() {
  // State to manage the open/closed status of the main menu
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  // State to manage the open/closed status of the mobile menu
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
  
  const { darkMode, toggleDarkMode } = useTheme();
  const { t } = useTranslation();

  // Array of navigation items for the dropdown menu
  const navListMenuItems = [
    {
      title: t('detec_image'),
      description: t('rediscover'),
      icon: PhotoIcon,
      link: "/image",
    },
    {
      title: t('detec_video'),
      description: t('matters'),
      icon: FilmIcon,
      link: "/video",
    },
    {
      title: t('detec_webcam'),
      description: t('behind'),
      icon: ComputerDesktopIcon,
      link: "/webcam",
    },
  ];

  // Mapping over navigation menu items to generate menu items
  const renderItems = navListMenuItems.map(({ icon, title, description, link }, key) => (
    <div key={key}>
      <a href={link} className="no-underline">
        <MenuItem className={`flex items-center gap-3 rounded-lg transition-colors duration-200 ${darkMode ? 'bg-gray-800 text-white hover:border-[#f8e049] hover:bg-gray-700' : 'bg-white text-gray-900 hover:border-[#f8e049] hover:bg-gray-100'}`}>
          <div className={`flex items-center justify-center rounded-lg p-2 ${darkMode ? 'bg-gray-700' : 'bg-blue-gray-50'}`}>
            {React.createElement(icon, {
              strokeWidth: 2,
              className: `h-6 w-6 ${darkMode ? 'text-white' : 'text-gray-900'}`,
            })}
          </div>
          <div>
            <Typography
              variant="h6"
              className={`flex items-center text-sm font-bold ${darkMode ? 'text-white' : 'text-gray-900'}`}
            >
              {title}
            </Typography>
            <Typography
              variant="paragraph"
              className={`text-xs font-medium ${darkMode ? 'text-gray-300' : 'text-blue-gray-500'}`}
            >
              {description}
            </Typography>
          </div>
        </MenuItem>
      </a>
    </div>
  ));

  return (
    <React.Fragment>
      <Menu
        open={isMenuOpen}
        handler={setIsMenuOpen}
        offset={{ mainAxis: 20 }}
        placement="bottom"
        allowHover={true}
      >
        <MenuHandler>
          <Typography as="div" variant="h6" className="font-medium">
            <ListItem
              className="flex items-center gap-2 py-2 pr-4 font-medium text-gray-900"
              selected={isMenuOpen || isMobileMenuOpen}
              onClick={() => setIsMobileMenuOpen((cur) => !cur)}
            >
              {t('detections')}
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`hidden h-3 w-3 transition-transform lg:block ${isMenuOpen ? 'rotate-180' : ''}`}
              />
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`block h-3 w-3 transition-transform lg:hidden ${isMobileMenuOpen ? 'rotate-180' : ''}`}
              />
            </ListItem>
          </Typography>
        </MenuHandler>
        <MenuList className={`hidden max-w-screen-xl rounded-xl lg:block ${darkMode ? 'bg-gray-800 text-white border border-[#f8e049]' : 'bg-white text-gray-900'}`}>
          <ul className="grid grid-cols-3 gap-y-2 outline-none outline-0">
            {renderItems}
          </ul>
        </MenuList>
      </Menu>
      <div className="block lg:hidden">
        <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
      </div>
    </React.Fragment>
  );
}

function NavList() {
  
  const { t } = useTranslation();

  // The main navigation component
  return (
    <div className="flex items-center justify-between">
      {/* List containing the navigation menu and Documentation link */}
      <List className="mt-4 mb-6 p-0 lg:mt-0 lg:mb-0 lg:flex-row lg:p-1">
        {/* Component for rendering the main navigation menu */}
        <NavListMenu />
        {/* Documentation link */}
        <Typography
          as="a"
          href="/documentation"
          variant="medium"
          color="blue-gray"
          className="font-medium"
        >
          {/* List item for Documentation */}
          <ListItem className="flex items-center gap-2 py-2 pr-4">
            {t('doc')}
          </ListItem>
        </Typography>
      </List>
    </div>
  );
}

 
export function HeaderConnected() {
  
  const [openNav, setOpenNav] = React.useState(false);
  const [openModal, setOpenModal] = useState(false);
    
  const [prenom, setPrenom] = useState('');
  const [nom, setNom] = useState('');
  const [dateN, setDateN] = useState('');
  const [mail, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
 /*
  try {
    const response = await axios.post('http://localhost:5000/api/upload', loggedUser: sessionStorage.getItem('loggedUser'), {
        responseType: 'blob', // Demande au serveur de renvoyer les données en tant qu'objet Blob
    });

    } catch (error) {
      console.error('Erreur lors de la récupération des valeurs : ', error);
    }

*/
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post('https://server.raccoonvision.com/api/getProfileInfo', {loggedUser: Cookies.get('loggedUser')}, {
            responseType: 'json',
        });

        setPrenom(response.data.userPrenom);
        setNom(response.data.userNom);
        setDateN(response.data.formattedDate);
        setEmail(response.data.userMail);

      } catch (error) {
        console.error('Erreur lors de la récupération des valeurs : ', error);
      }
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false),
    );
  }, []);
 
  return (
    <React.Fragment>
        <Helmet>
            <title>RaccoonVision</title>
            <link rel="icon" type="image/png" href={imgLogo} />
        </Helmet>
        <Navbar className={`mx-auto max-w-screen-xl px-4 py-2 sticky`}>
            <div className="flex items-center justify-between text-blue-gray-900">
                <Typography
                    as="a"
                    href="/"
                    variant="h6"
                    className="mr-4 py-1.5 lg:ml-2"
                >
                    RaccoonVision
                </Typography>
                <div className="hidden lg:block">
                    <NavList />
                </div>
                <ToggleDarkMode />
                <LanguageSelector />
                <div className="hidden gap-2 lg:flex">
                    <ProfileMenu openModal={() => setOpenModal(true)}/>
                </div>
                
                <IconButton
                    variant="text"
                    color="blue-gray"
                    className="lg:hidden"
                    onClick={() => setOpenNav(!openNav)}
                >
                    {openNav ? (
                    <XMarkIcon className="h-6 w-6" strokeWidth={2} />
                    ) : (
                    <Bars3Icon className="h-6 w-6" strokeWidth={2} />
                    )}
                </IconButton>
            </div>
            <Collapse open={openNav}>
                <NavList />
                <div className="flex w-full flex-nowrap items-center gap-2 lg:hidden">
                    <ProfileMenu openModal={() => setOpenModal(true)}/>
                </div>
            </Collapse>
        </Navbar>
        <Modal
          show={openModal}
          onClose={() => setOpenModal(false)}
          className="custom-modal" 
        >
          <Modal.Header />
          <Modal.Body style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Card className="card-loginsignup" color="transparent" shadow={true}>
                  <Typography variant="h4" color="blue-gray">
                    {t('profile')}
                  </Typography>
                  <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
                      <div className="mb-1 flex flex-col gap-6">
                          <Typography variant="h6" color="blue-gray" className="-mb-3">
                            {t('prenom')}
                          </Typography>
                          <Input
                              type="text" 
                              id="prenom" 
                              value={prenom} 
                              readOnly
                              size="lg"
                              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                              labelProps={{
                              className: "before:content-none after:content-none",
                              }}
                          />
                          <Typography variant="h6" color="blue-gray" className="-mb-3">
                            {t('nom')}
                          </Typography>
                          <Input
                              type="text" 
                              id="nom" 
                              value={nom} 
                              readOnly
                              size="lg"
                              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                              labelProps={{
                              className: "before:content-none after:content-none",
                              }}
                          />
                          <Typography variant="h6" color="blue-gray" className="-mb-3">
                            {t('date_naiss')}
                          </Typography>
                          <Input
                                  type="date"
                                  id="dateN"
                                  value={dateN}
                                  readOnly
                                  size="lg"
                                  className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                                  labelProps={{
                                      className: "before:content-none after:content-none",
                                  }}
                              />
                          <Typography variant="h6" color="blue-gray" className="-mb-3">
                            {t('mail')}
                          </Typography>
                          <Input
                              type="email"
                              id="mail"
                              value={mail}
                              readOnly
                              size="lg"
                              placeholder="name@mail.com"
                              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                              labelProps={{
                              className: "before:content-none after:content-none",
                              }}
                          />
                          {/*
                          <Typography variant="h6" color="blue-gray" className="-mb-3">
                              Password
                          </Typography>
                          <Input
                              type="password"
                              id="password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}                       
                              size="lg"
                              placeholder="********"
                              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                              labelProps={{
                              className: "before:content-none after:content-none",
                              }}
                          />
                          */}
                      </div>
                  </form>
              </Card>
          </Modal.Body>

        </Modal>
    </React.Fragment>
  );
}

export default HeaderConnected;