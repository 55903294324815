import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from './contexts/ThemeContext'; // Assurez-vous que ThemeProvider est correctement importé
import './i18n'; 

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// To start measuring performance in our app, we pass a function
// to log results, for example: reportWebVitals(console.log)
reportWebVitals();

