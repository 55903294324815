import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LogInSignUp from './pages/LogInSignUp';
import Deconnexion from './pages/Deconnexion';
import Sauvegarde from './pages/SavedFile';
import SauvegardeImage from './pages/SavedImage';
import SauvegardeDossier from './pages/SavedFolder';
import Image from './pages/Image';
import Video from './pages/Video';
import Webcam from './pages/Webcam';
import Documentation from './pages/Documentation';
import HomePage from './pages/HomePage';
import Error404 from './pages/Error404';
import { LegalNotice, TermsOfUse, PrivacyPolicy } from './pages/LegalDocs';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/connexion" element={<LogInSignUp />} />
        <Route path="/deconnexion" element={<Deconnexion />} />
        <Route path="/saved" element={<Sauvegarde />} />
        <Route path="/saved/images/:id" element={<SauvegardeImage />} />
        <Route path="/saved/:id" element={<SauvegardeDossier />} />
        <Route path="/image" element={<Image />} />
        <Route path="/webcam" element={<Webcam />} />
        <Route path="/video" element={<Video />} />
        <Route path="/documentation" element={<Documentation />} />
        <Route path="/legal_notice" element={<LegalNotice />} />
        <Route path="/terms_of_use" element={<TermsOfUse />} />
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </Router>
);
}

export default App;
