import React, { useState, useEffect } from 'react';
import { Navigate, useParams, useNavigate } from 'react-router-dom';
import Header from './Header';
import HeaderConnected from './HeaderConnected';
import Footer from './Footer';
import { useTheme } from '../contexts/ThemeContext';
import { useTranslation } from 'react-i18next';
import '../stylesheets/saved_file.css';
import axios from 'axios';
import Cookies from 'js-cookie';
import {
    List,
    ListItem,
    ListItemSuffix,
    Card,
    IconButton,
} from "@material-tailwind/react";
import { Button } from 'flowbite-react';

function TrashIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="h-5 w-5"
        >
            {/* SVG path for the trash icon */}
            <path
                fillRule="evenodd"
                d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 11-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                clipRule="evenodd"
            />
        </svg>
    );
}

function SavedImage() {
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();

    const loggedUser = Cookies.get('loggedUser');
    const isLoggedIn = loggedUser !== undefined;

    const { darkMode, toggleDarkMode } = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            try {
                const response = await axios.post('https://server.raccoonvision.com/api/filesById', {
                    loggedUser: Cookies.get('loggedUser'),
                    fileId: id,
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                setFiles(response.data);
            } catch (error) {
                console.error('Error fetching files: ' + error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const handleDeleteFile = async (idFichier) => {
        try {
            axios.delete(`https://server.raccoonvision.com/api/files/${idFichier}`);
            setFiles((prevFiles) => prevFiles.filter(file => file.idFichier !== idFichier));
            navigate('/saved');
        } catch (error) {
            console.error('Error deleting file: ' + error.message);
        }
    };

    const handleDownloadFile = async (file) => {
        try {
            const blobResponse = await fetch(`data:image/png;base64,${file.image}`);
            const blob = await blobResponse.blob();
            const url = URL.createObjectURL(blob);

            const downloadLink = document.createElement('a');
            downloadLink.href = url;
            downloadLink.download = `result.jpg`;
            downloadLink.click();
        } catch (error) {
            console.error('Error downloading file: ' + error.message);
        }
    };

    if (!isLoggedIn) {
        return <Navigate to="/connexion" />;
    }

    return (
        <div className={`App ${darkMode ? 'dark' : 'light'}`}>
            {isLoggedIn ? <HeaderConnected /> : <Header />}
            
            <div className="content-save flex flex-col justify-center">
            <Button 
                color='blue'
                className='go-back-button' onClick={() =>{
                    navigate(`/saved`);
            }}>{t('go_back')}</Button>
                <div className="flex top-0 justify-center">
                    {files.length === 0 ? (
                        <p className="text-gray-700">{t('fichier_inexistant')}</p>
                    ) : (
                        <ul className="flex gap-20 justify-center flex-wrap">
                            {files.map(file => (
                                <div key={file.idFichier}>
                                    <Card className="w-120">
                                        <List>
                                        <ListItem ripple={false} className="py-1 pr-1 pl-4">                                                
                                            <div className="left-0">
                                                    <img
                                                        className="aspect-w-16 aspect-h-9 w-full h-full object-cover"
                                                        src={`data:image/png;base64,${file.image}`}
                                                        alt={`File ${file.idFichier}`}
                                                    />
                                                    <p className="text-2xl text-gray-700 pl-3">{file.dateCreation}</p>
                                                </div>
                                                
                                                <ListItemSuffix className="ml-5">
                                                    <Button className="ml-5" color="blue" onClick={() => handleDownloadFile(file)}>{t('download')}</Button>
                                                    <Button className="ml-5" color="blue" onClick={() => handleDeleteFile(file.idFichier)}><TrashIcon /></Button>
                                                </ListItemSuffix>
                                            </ListItem>
                                        </List>
                                    </Card>
                                </div>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default SavedImage;
